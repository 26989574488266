
/*
******* NOTICE **********
This is an auto generated file. Please DO NOT modify !!
if you want to add any new config, please have a look at aws-init.ts
*************************
*/
import { ResourcesConfig } from 'aws-amplify';

export interface AwsConfig {
  clientApp: ResourcesConfig;
  adminApp: ResourcesConfig;
  familyApp: ResourcesConfig;

  rootDomain: string;
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
  authenticationFlowType?: string;
  aws_user_files_s3_bucket_region: string;
  certificates: {
    app: string;
    admin: string;
    family: string;
    api: string;
  }
  kmsKeyId: string;
  databases?: {
    [key: string]: {
      latestStreamArn: string;
    }
  };
  familyAppUrl: string;
  apolloAppUrl: string;
  adminAppUrl: string;
};

    

    export const awsConfig: AwsConfig = {
  adminApp: {
    API: {
      GraphQL: {
        endpoint: 'https://admin.api.resparke.com/graphql',
        region: 'ap-southeast-2',
        defaultAuthMode: 'userPool'
      }
    },
    Auth: {
      Cognito: {
        userPoolId: 'ap-southeast-2_vjIJL1BKJ',
        userPoolClientId: '1lldp4iovej6vl42ldth95t7kr',
        identityPoolId: 'ap-southeast-2:33852f76-e466-42c6-ad7a-cd381fa2c295',
        allowGuestAccess: true
      }
    }
  },
  clientApp: {
    API: {
      GraphQL: {
        endpoint: 'https://client.api.resparke.com/graphql',
        region: 'ap-southeast-2',
        defaultAuthMode: 'userPool'
      }
    },
    Auth: {
      Cognito: {
        userPoolId: 'ap-southeast-2_vjIJL1BKJ',
        userPoolClientId: '1lldp4iovej6vl42ldth95t7kr',
        identityPoolId: 'ap-southeast-2:33852f76-e466-42c6-ad7a-cd381fa2c295',
        allowGuestAccess: true
      }
    }
  },
  familyApp: {
    API: {
      GraphQL: {
        endpoint: 'https://family.api.resparke.com/graphql',
        region: 'ap-southeast-2',
        defaultAuthMode: 'userPool'
      }
    },
    Auth: {
      Cognito: {
        userPoolId: 'ap-southeast-2_vjIJL1BKJ',
        userPoolClientId: '1lldp4iovej6vl42ldth95t7kr',
        identityPoolId: 'ap-southeast-2:33852f76-e466-42c6-ad7a-cd381fa2c295',
        allowGuestAccess: true
      }
    }
  },
  region: 'ap-southeast-2',
  userPoolId: 'ap-southeast-2_vjIJL1BKJ',
  userPoolWebClientId: '1lldp4iovej6vl42ldth95t7kr',
  aws_user_files_s3_bucket_region: 'ap-southeast-2',
  apolloAppUrl: 'https://app.resparke.com',
  familyAppUrl: 'https://family.resparke.com',
  adminAppUrl: 'https://admin.resparke.com',
  kmsKeyId: '669ed957-e4b3-4f8b-bfa3-040f0e54943b',
  certificates: {
    admin: 'arn:aws:acm:us-east-1:877224049810:certificate/45be5ba5-9ce7-436f-905a-11d62d6cb1fe',
    family: 'arn:aws:acm:us-east-1:877224049810:certificate/0839a595-0508-4525-8d9b-db3496f380a3',
    app: 'arn:aws:acm:us-east-1:877224049810:certificate/7eaa57f9-d0eb-4ee2-a827-80d2d359c95a',
    api: 'arn:aws:acm:us-east-1:877224049810:certificate/05e536dd-0076-42c5-8def-860bdd21078e'
  },
  databases: {
    'apollo-accessTokens': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:877224049810:table/apollo-accessTokens/stream/2024-03-20T01:38:41.157'
    },
    'apollo-artists': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:877224049810:table/apollo-artists/stream/2024-03-28T04:10:51.992'
    },
    'apollo-categories2': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:877224049810:table/apollo-categories2/stream/2023-11-29T05:48:20.658'
    },
    'apollo-events': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:877224049810:table/apollo-events/stream/2024-01-01T08:51:52.129'
    },
    'apollo-facilities': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:877224049810:table/apollo-facilities/stream/2023-03-28T09:44:12.061'
    },
    'apollo-logs': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:877224049810:table/apollo-logs/stream/2024-07-17T13:05:27.888'
    },
    'apollo-media': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:877224049810:table/apollo-media/stream/2023-11-29T05:48:20.539'
    },
    'apollo-migrations': { latestStreamArn: '' },
    'apollo-organisations': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:877224049810:table/apollo-organisations/stream/2023-03-28T09:44:12.256'
    },
    'apollo-residents': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:877224049810:table/apollo-residents/stream/2023-03-28T09:44:11.847'
    },
    'apollo-search': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:877224049810:table/apollo-search/stream/2024-05-03T02:13:13.337'
    },
    'apollo-search-aggregation': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:877224049810:table/apollo-search-aggregation/stream/2024-03-13T02:17:06.326'
    },
    'apollo-streamLogs': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:877224049810:table/apollo-streamLogs/stream/2023-03-28T09:44:12.151'
    },
    'apollo-tags': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:877224049810:table/apollo-tags/stream/2024-03-26T09:44:48.794'
    },
    'apollo-uploads': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:877224049810:table/apollo-uploads/stream/2024-10-02T11:54:49.789'
    },
    'apollo-userSessions': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:877224049810:table/apollo-userSessions/stream/2023-03-28T09:44:11.641'
    },
    'apollo-users': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:877224049810:table/apollo-users/stream/2023-03-28T09:44:11.985'
    },
    'apollo-videoLogs': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:877224049810:table/apollo-videoLogs/stream/2024-02-20T03:19:35.654'
    }
  },
  rootDomain: 'resparke.com'
};

  